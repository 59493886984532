.gradient {
  background: linear-gradient(240.68deg, #c6ac73 37.84%, #9f854c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Open Sans";
}

.gradientleft {
  background: linear-gradient(90deg, #c6ac73 -0.98%, #9f854c 53.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.homebg::after {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background:  linear-gradient(180deg, #C4C4C4 0%, rgba(196, 196, 196, 0) 71.99%, rgba(196, 196, 196, 0) 100%);
  z-index: -1;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important; 
}
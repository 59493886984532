#root,
body,
html,
canvas {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  /* position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none; */
  background: #272730;
  font-family: 'Open Sans';

}

body,
html {
  overflow-x: hidden;
}

.close {
  position: absolute !important;
  right: 20px;
  top: 10px;
  z-index: 99;
  
}
.close svg{
  color: #fff;
}

.demoPage {
  box-shadow: 2px 3px 20px black, 0 0 60px #8a4d0f inset;
  background: #fffef0;
  padding: 20px;
}

.book-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex: 1
}

.global-stats {
  position: relative !important;
  bottom: 49px !important;
  top: auto !important;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-BoldItalic.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-ExtraBold.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-Italic.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-Light.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-LightItalic.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-MediumItalic.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-Medium.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-SemiBoldItalic.woff2') format('woff2'),
    url('../assets/fonts/OpenSans/OpenSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

.checkbox {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.checkbox label {
  display: flex;

  justify-content: center;
}

.checkbox .check-mark {
  background-image: url(../assets/emptycheckbox.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

#Math {
  display: none;
}

.checkbox input:checked~.check-mark {
  border: 0
}

.checkbox input:checked~.check-mark:after {
  background-image: url(../assets/checkbox.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
  height: 20px;
  content: "";
  position: absolute;
}

 #first-golden-book{
  cursor: pointer;
  } 

  #sec-golden-book{
    cursor: pointer;
  }
 
  #fourth-vol{
    cursor: pointer;
  }


  #fifth-volume {
    cursor: pointer;
  } 

  #sixth-golden-book {
    cursor: pointer;
  }


  #tenth-vol {
    cursor: pointer;
  }


  #nav-buttons {
    cursor: pointer;
  }

 

  #opening-page-turn{

  }


  #hand-icon {

  }